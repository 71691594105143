import React from 'react';
import { Box, Grid2 } from '@mui/material';
import HeroText from './HeroText';
import HeroImage from './HeroImage';

const HeroSection = () => {
  return (
    <Box sx={{ paddingY: 4, paddingTop: { xs: 6, sm: 8, md: 8 }, }}>
      <Grid2 container spacing={4} alignItems="center">
        {/* Hero Text */}
        <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 2, md: 1 }}>
          <HeroText />
        </Grid2>

        {/* Hero Image */}
        <Grid2 size={{ xs: 12, md: 6 }} order={{ xs: 1, md: 2 }} textAlign="center">
          <HeroImage />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default HeroSection;
