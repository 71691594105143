/**
 * Configuration settings for the application.
 */
const config = {
  base_url: process.env.REACT_APP_BASE_URL,   // Base URL for the API
  libraryPanelUrl: process.env.REACT_APP_LIBRARY_PANEL,
  studentAppDownloadUrl: process.env.REACT_APP_STUDENT_APP_DOWNLOAD_URL,
  linkedInUrl: process.env.REACT_APP_LINKEDIN_URL,
  faceBookUrl: process.env.REACT_APP_FACEBOOK_URL,
  twitterUrl: process.env.REACT_APP_TWITTER_URL,
};

export default config;
