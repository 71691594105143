import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

const FeatureItem = ({ Icon, title, description }) => (
    <Stack direction="row" gap={2} sx={{ mb: 2 }}>
        <Icon sx={{ width: 40, height: 40, alignSelf: 'center', color: "primary.main" }} />
        <Box sx={{ textAlign: "left" }}>
            <Typography variant="body1" sx={{ fontSize: "20px" }}>
                {title}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: "14px" }}>
                {description}
            </Typography>
        </Box>
    </Stack>
);

export default FeatureItem;
