import React from "react";
import { Box, Typography, Avatar, Stack } from "@mui/material";

// Rating star styles
const renderRating = (rating) =>
  Array(5)
    .fill("")
    .map((_, index) => (
      <Box
        key={index}
        sx={{
          width: 16,
          height: 16,
          backgroundColor: index < rating ? "#FFD700" : "lightgray",
          clipPath:
            "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
        }}
      />
    ));

const TestimonialHeader = ({ image, name, designation, rating }) => {
  return (
    <Stack direction="row" gap={2}>
      <Avatar src={image} alt={name} sx={{ width: 70, height: 70 }} />
      <Box>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {designation}
        </Typography>
        <Box sx={{ mt: 1, display: "flex", gap: 0.5 }}>{renderRating(rating)}</Box>
      </Box>
    </Stack>
  );
};

export default TestimonialHeader;
