import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        bgcolor: 'background.default',
        color: 'text.primary',
      }}
    >
      <Typography variant="h1" sx={{ fontSize: '6rem', fontWeight: 'bold', color: 'primary.main' }}>
        404
      </Typography>
      <Typography variant="h4" sx={{ marginBottom: '16px', fontWeight: 500 }}>
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '32px', maxWidth: '400px' }}>
        It seems you've hit a broken link or entered a URL that doesn't exist. Please check the address or return to the homepage.
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          Go to Home
        </Button>
        <Button
          component={Link}
          to="/contact"
          variant="outlined"
          color="secondary"
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          Contact Support
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
