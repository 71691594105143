import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Typography, TextField, Button } from '@mui/material';
import { contactUsApi } from '../../api';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Formik Initialization
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Name must be at least 2 characters')
        .required('Name is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      subject: Yup.string()
        .min(5, 'Subject must be at least 5 characters')
        .required('Subject is required'),
      message: Yup.string()
        .min(10, 'Message must be at least 10 characters')
        .required('Message is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      const res = await contactUsApi().send(values);
      Swal.fire({
        title: 'Success',
        text: res['message'] || 'Your message has been sent successfully',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });
      resetForm();
      setIsSubmitting(false);
    },
  });

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: '12px',
        boxShadow: 3,
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Ensures content is spaced evenly
        minHeight: { xs: 'auto', md: '500px' }, // Matches height for responsiveness
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
        Get In Touch
      </Typography>
      <Typography variant="body2" sx={{ mb: 3 }}>
        Have questions about our system? Fill out the form below, and we’ll get back to you as soon as possible.
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          display: 'grid',
          gap: 2,
        }}
      >
        {/* Name and Email Fields */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
            gap: 2,
          }}
        >
          <TextField
            fullWidth
            type="text"
            size={Boolean(formik.errors.name) ? "small" : "medium"}
            name="name"
            label="Your Name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            fullWidth
            type="email"
            size={Boolean(formik.errors.email) ? "small" : "medium"}
            name="email"
            label="Your Email"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Box>

        {/* Subject Field */}
        <TextField
          fullWidth
          type="text"
          size={Boolean(formik.errors.subject) ? "small" : "medium"}
          name="subject"
          label="Subject"
          variant="outlined"
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.subject && Boolean(formik.errors.subject)}
          helperText={formik.touched.subject && formik.errors.subject}
        />

        {/* Message Field */}
        <TextField
          fullWidth
          type="text"
          name="message"
          label="Message"
          size={Boolean(formik.errors.message) ? "small" : "medium"}
          variant="outlined"
          multiline
          rows={4}
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          sx={{
            bgcolor: 'primary.main',
            color: 'common.white',
            fontWeight: 'bold',
            borderRadius: '20px',
            textTransform: 'none',
            width: '150px',
            alignSelf: 'center',
          }}
          disabled={isSubmitting}
        >
          {
            isSubmitting
              ? "Sending...."
              : 'Send'
          }
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
