import React, { useEffect } from 'react';
import { Box, IconButton, Drawer, List, ListItem } from '@mui/material';
import NavLink from './NavLink';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';

const NavMenu = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
    const [activeNav, setActiveNav] = React.useState('Home');
    const location = useLocation();
    const navigate = useNavigate();

    const navItems = [
        { title: "Home", url: "#" },
        { title: "About", url: "#about-us" },
        { title: "Features", url: "#features" },
        { title: "How It Works", url: "#how-it-works" },
        // { title: "Pricing", url: "#pricing" },
        { title: "Contact", url: "#contact-us" },
    ];

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleNavItemClick = ({ url, title }) => () => {
        const sectionId = url.replace('#', ''); // Extract section ID (e.g., "about-us")

        if (location.pathname !== '/') {
            // If not on the homepage, navigate to the homepage first
            navigate(`/${url}`);
            // Wait for the navigation to complete before scrolling
            setTimeout(() => {
                scrollToSection(sectionId);
            }, 300); // Adjust the delay as needed (300ms works in most cases)
        } else {
            // If already on the homepage, scroll to the section directly
            scrollToSection(sectionId);
        }

        setActiveNav(title);
        if (isMobileMenuOpen) toggleMobileMenu();
    };

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    // Automatically scroll to section when hash changes
    useEffect(() => {
        if (location.hash) {
            const sectionId = location.hash.replace('#', ''); // Extract section ID from hash
            scrollToSection(sectionId);
        }
    }, [location.hash]);

    return (
        <>
            {/* Desktop Menu */}
            <Box
                component="nav"
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    gap: 3,
                    typography: 'body1',
                    color: 'text.primary',
                }}
            >
                {navItems.map(({ title, url }, index) => (
                    <NavLink
                        key={index}
                        href={url}
                        label={title}
                        active={activeNav === title}
                        onClick={handleNavItemClick({ url, title })}
                        sx={{
                            color: activeNav === title ? 'primary.main' : 'text.primary',
                            fontWeight: activeNav === title ? 'bold' : 'normal',
                        }}
                    />
                ))}
            </Box>

            {/* Mobile Menu Toggle Button */}
            <IconButton
                onClick={toggleMobileMenu}
                sx={{ display: { xs: 'inline-flex', md: 'none' }, color: 'primary.main' }}
            >
                <MenuIcon />
            </IconButton>

            {/* Drawer for Mobile Menu */}
            <Drawer anchor="right" open={isMobileMenuOpen} onClose={toggleMobileMenu}>
                <List sx={{ width: 200, bgcolor: 'background.default', color: 'text.primary' }}>
                    {navItems.map(({ title, url }) => (
                        <ListItem
                            key={title}
                            sx={{
                                bgcolor: activeNav === title ? 'action.selected' : 'background.default',
                                '&:hover': { bgcolor: 'action.hover' },
                            }}
                        >
                            <NavLink
                                href={url}
                                label={title}
                                active={activeNav === title}
                                onClick={handleNavItemClick({ url, title })}
                                sx={{
                                    width: '100%',
                                    color: activeNav === title ? 'primary.main' : 'text.primary',
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
};

export default NavMenu;
