import React from 'react';
import { Box } from '@mui/material';
import ContactInfoHeader from './ContactInfoHeader';
import ContactInfoList from './ContactInfoList';

const ContactInfo = () => (
  <Box
    sx={{
      bgcolor: 'primary.main',
      color: 'common.white',
      borderRadius: '12px',
      boxShadow: 3,
      p: 3,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between', // Ensures content is spaced evenly
      minHeight: { xs: 'auto', md: '500px' }, // Matches height for responsiveness
    }}
  >
    <ContactInfoHeader />
    <ContactInfoList />
  </Box>
);

export default ContactInfo;
