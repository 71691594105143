// API endpoint configuration
const apiRoutes = {
  auth: {
    registerLibrary: {
      url: "/auth/register-library",
      method: "post",
      successCode: 201,
      showSuccessToast: false,
      showErrorToast: false,
    },
    resetPassword: {
      url: "/auth/reset-password",
      method: "post",
      successCode: 200,
      showSuccessToast: false,
      showErrorToast: false,
    },
    checkCredentials: {
      url: "/auth/is-credentials-already-used",
      method: "post",
      successCode: 200,
      showSuccessToast: false,
      showErrorToast: false,
    },
  },
  contactUs: {
    send: {
      url: "/contact-us/send",
      method: "post",
      successCode: 201,
      showSuccessToast: false,
      showErrorToast: false,
    },
  },
};

export default apiRoutes;
