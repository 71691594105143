import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import DownloadApp from '../Footer/DownloadApp';
import config from '../../config/config';

const HeroText = () => {
  return (
    <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
      <Typography
        sx={{
          mb: 3,
          typography: { xs: 'h3', sm: 'h2', md: 'h1' },
        }}
      >
        Easy Booking with <br /> Smart Study Spaces
      </Typography>
      <Typography
        variant="h5"
        sx={{
          mb: 3,
          color: 'text.secondary',
        }}
      >
        Enhancing connections between students and libraries to provide accessible, adaptable, and dependable study environments..
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 4,
          color: 'text.primary',
        }}
      >
        Easily book your ideal study spot with our online seat reservation system. Enjoy a hassle-free experience and ensure a quiet, productive space whenever you need it.<br />With our platform, librarians can easily oversee seat reservations, monitor availability, and provide students with a seamless booking experience, enhancing library resource management."
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: { xs: 'center', md: 'flex-start' } }}>
        <Button variant="contained" target='_blank' href={config.libraryPanelUrl} sx={{ textTransform: 'none' }} >
          As Librarians
        </Button>
        <DownloadApp variant='outlined'>
          As Students
        </DownloadApp>
      </Box>
    </Box>
  );
};

export default HeroText;
