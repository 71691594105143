import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { AccessTime } from '@mui/icons-material';

const ContactInfoItem = ({ Icon, primary, secondary }) => (
  <Stack direction="row" gap={2} sx={{ mb: 4 }}>
    <Icon sx={{ width: 25, height: 25, alignSelf: 'center' }} />
    <Box sx={{ textAlign: "left" }}>
      <Typography variant="body1" sx={{ fontSize: "18px" }}>
        {primary}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "12px" }}>
        {secondary}
      </Typography>
    </Box>
  </Stack>
);

const ContactInfoList = () => {
  const contactItems = [
    {
      Icon: LocationOnIcon,
      primary: 'Our Location',
      secondary: 'ASHOK KUMAR, Babu Rajendra Marg, Opp. IIT Academy, Main, Pal Rd, near Baraktullah Khan Stadium, Jodhpur, Rajasthan 342001',
    },
    {
      Icon: PhoneIcon,
      primary: 'Phone Number',
      secondary: (
        <Box varient="a" href="tel:">Under Development</Box>
      ),
    },
    {
      Icon: EmailIcon,
      primary: 'Email Address',
      secondary: (
        <>
          <Box varient="a" href="mailto:Under Development">Under Development</Box>
          <Box varient="a" href="mailto:Under Development">Under Development</Box>
        </>
      ),
    },
    {
      Icon: AccessTime,
      primary: 'Office Hours',
      secondary: 'Mon-Fri: 9:00 AM - 6:00 PM',
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {contactItems.map((item, index) => (
        <ContactInfoItem key={index} {...item} />
      ))}
    </Box>
  );
};

export default ContactInfoList;
