import React from 'react';
import { Typography, useTheme } from '@mui/material';

const NavLink = ({ href, label, active, onClick, sx = {} }) => {
    const theme = useTheme();

    return (
        <Typography
            component="a"
            href={href}
            sx={{
                ...sx,
                color: active ? 'primary.main' : 'text.primary',
                textDecoration: 'none',
                '&:hover': { color: 'primary.dark' },
                ...theme.typography.navLink, // Use global typography styles for nav links
            }}
            onClick={onClick}
        >
            {label}
        </Typography>
    );
};

export default NavLink;
