import React, { useState } from "react";
import UnifiedTestimonial from "./UnifiedTestimonial";

const TestimonialCard = ({ image, name, designation, text, rating }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {/* Testimonial Card */}
      <UnifiedTestimonial
        name={name}
        designation={designation}
        text={text}
        rating={rating}
        image={image}
        onClick={() => setOpenModal(true)} // Open modal on click
      />

      {/* Testimonial Modal */}
      {openModal && (
        <UnifiedTestimonial
          name={name}
          designation={designation}
          text={text}
          rating={rating}
          image={image}
          isModal={true}
          onClose={() => setOpenModal(false)} // Close modal on click
        />
      )}
    </>
  );
};

export default TestimonialCard;
