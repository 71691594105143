import React from 'react';
import { Box, Typography, Grid2 } from '@mui/material';
import { CalendarToday, Lightbulb, LocalLibrary, VerifiedUser } from '@mui/icons-material';

const valuesData = [
  {
    Icon: LocalLibrary,
    title: "Accessibility",
    description: "We strive to make library spaces accessible to all students, regardless of their background or study needs.",
    backgroundColor: 'primary.light', // Light blue to signify openness and accessibility
    iconColor: 'primary.dark',        // Darker blue to represent knowledge and education
  },
  {
    Icon: CalendarToday,
    title: "Flexibility",
    description: "We believe in giving students the freedom to book study spaces on their own terms, with fair and flexible pricing.",
    backgroundColor: 'secondary.light', // Light pink for adaptability and friendliness
    iconColor: 'secondary.dark',        // Darker pink for a welcoming and warm feeling
  },
  {
    Icon: Lightbulb,
    title: "Innovation",
    description: "We are committed to using technology to modernize libraries and enhance the study experience for students.",
    backgroundColor: 'info.light',      // Light blue for creativity and progress
    iconColor: 'info.main',             // Darker blue to reflect technology and innovation
  },
  {
    Icon: VerifiedUser,
    title: "Transparency",
    description: "Our system is designed to foster trust through clear processes and fair policies for both students and libraries.",
    backgroundColor: 'success.light', // Light green for clarity and transparency
    iconColor: 'success.dark',        // Darker green to reflect security and reliability
  },
];

const ValueItem = ({ Icon, title, description, backgroundColor, iconColor }) => (
  <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
    <Box
      sx={{
        backgroundColor,
        textAlign: 'center',
        padding: 2,
        height: "100%",
        borderRadius: 2,
        boxShadow: 2,
      }}
    >
      <Icon fontSize="large" sx={{ color: iconColor }} />
      <Typography variant="subtitle1" sx={{ fontSize: "30px" }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Box>
  </Grid2>
);

const Values = () => (
  <>
    <Typography variant="h6" sx={{ mb: 2 }}>
      Our Values
    </Typography>
    <Grid2 container spacing={4} justifyContent="center">
      {valuesData.map((value) => <ValueItem {...value} />)}
    </Grid2>
  </>
);

export default Values;
