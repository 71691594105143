import React from "react";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import TestimonialCard from "./TestimonialCard";

// Main Testimonials Component
const Testimonials = () => {
  const testimonials = [
    {
      text: "This system revolutionized how I manage my library! It's easy, efficient, and saves me a ton of time.",
      name: "Aarav Patel",
      designation: "Library Manager",
      image: "",
      rating: 4
    },
    {
      text: "Booking seats for self-study has never been easier. I love the flexibility this system provides.",
      name: "Ishita Sharma",
      designation: "Student",
      image: "",
      rating: 5
    },
    {
      text: "The user-friendly interface makes it easy for both librarians and students. Highly recommended!",
      name: "Rohan Gupta",
      designation: "Librarian",
      image: "",
      rating: 4
    },
    {
      text: "I can now manage attendance digitally without worrying about manual records. Amazing tool!",
      name: "Ananya Deshmukh",
      designation: "Library Owner",
      image: "",
      rating: 5
    },
    {
      text: "This platform connects libraries globally, offering students the best places to study. Fantastic idea!",
      name: "Aditya Nair",
      designation: "University Student",
      image: "",
      rating: 5
    },
    {
      text: "Our library staff has saved so much time by using this system. It's a great innovation.",
      name: "Meera Reddy",
      designation: "Senior Librarian",
      image: "",
      rating: 4
    },
    {
      text: "The flexibility of short-term subscriptions is perfect for students like me. Highly useful!",
      name: "Kartik Singh",
      designation: "College Student",
      image: "",
      rating: 5
    },
    {
      text: "I appreciate how this system simplifies seat reservations for students. It’s a great help!",
      name: "Pooja Verma",
      designation: "Librarian",
      image: "",
      rating: 4
    },
    {
      text: "This system has improved the overall experience of managing library operations. Outstanding work!",
      name: "Rajiv Pillai",
      designation: "Library Administrator",
      image: "",
      rating: 5
    },
    {
      text: "As a student, I find it extremely convenient to book library seats anytime I need. Great tool!",
      name: "Sanya Kapoor",
      designation: "Engineering Student",
      image: "",
      rating: 4
    }
  ];

  return (
    <Box
      sx={{
        py: 4,
        bgcolor: "background.default",
        textAlign: "center",
      }}
    >
      {/* Section Title */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 1 }}>
          User Feedback
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Hear from our librarians and students about their experiences with our system
        </Typography>
      </Box>

      {/* Swiper Carousel */}
      <Swiper
        modules={[Pagination, Autoplay]}
        loop={true}
        autoplay={{ delay: 5000 }}
        pagination={{ clickable: true }}
        breakpoints={{
          320: { slidesPerView: 1 },
          1200: { slidesPerView: 3 },
        }}
        style={{ paddingBottom: "40px" }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <TestimonialCard {...testimonial} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Testimonials;
