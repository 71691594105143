import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Logo from './Logo';
import NavMenu from './NavMenu';
import { useTheme } from '@mui/material/styles';

const Header = () => {
    const theme = useTheme();
    const [scrolled, setScrolled] = useState(false);

    // Add a scroll event listener to add background color on scroll
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 20,
                left: 0,
                width: '100%',
                zIndex: 1100, // Ensure it stays above other components
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)} !important`,
                    margin: "auto",
                    maxWidth: "1145px",
                    transition: 'all 0.5s',
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: scrolled ? theme.shadows[3] : 'none',
                    borderRadius: theme.shape.borderRadius,
                    justifyContent: "space-between",
                }}
            >
                <Box component="a" href='/#'>
                    <Logo />
                </Box>
                <NavMenu />
            </Box>
        </Box>
    );
};

export default Header;
