import React from 'react';
import { Grid2, Typography } from '@mui/material';

const FooterSection = ({ title, children, textAlign }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 3 }} textAlign={textAlign} >
      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
        {title}
      </Typography>
      {children}
    </Grid2 >
  );
};

export default FooterSection;
