import React from 'react';
import { Link } from '@mui/material';

const FooterLinks = ({ links }) => {
  return (
    <>
      {links.map((link) => (
        <Link
          key={link.label}
          href={link.href}
          color="inherit"
          underline="hover"
          sx={{ display: 'block', mb: 1 }}
        >
          {link.label}
        </Link>
      ))}
    </>
  );
};

export default FooterLinks;
