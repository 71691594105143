import React from 'react';
import { Box, Grid2, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FooterSection from './FooterSection';
import SocialIcons from './SocialIcons';
import FooterLinks from './FooterLinks';
import DownloadApp from './DownloadApp';
import googlePlayStoreIcon from '../../assets/images/google-play-logo.svg';
import Logo from '../Header/Logo';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const usefulLinks = [
    { label: 'Home', href: '' },
    { label: 'About Us', href: '#about-us' },
    { label: 'Services', href: '/services' },
    { label: 'Contact', href: '#contact-us' },
  ];

  const productHelpLinks = [
    { label: 'FAQ', href: '/faq' },
    { label: 'Privacy Policy', href: '/privacy-policy' },
    { label: 'Support', href: '/support' },
    { label: 'Terms & Conditions', href: '/terms-conditions' },
  ];

  return (
    <Box sx={{ bgcolor: "action.hover", p: 4, color: theme.palette.text.primary, borderTop: `1px solid ${theme.palette.divider}` }}>
      <Grid2 container spacing={4} justifyContent="center">
        {/* Logo and Description */}
        <FooterSection title={<Logo />} textAlign={isMobile ? 'center' : 'left'}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Your one-stop solution for seat booking and study space management across libraries. Helping students and libraries connect seamlessly.
          </Typography>
          <SocialIcons isMobile={isMobile} />
        </FooterSection>

        {/* Useful Links */}
        <FooterSection title="Useful Links" textAlign={isMobile ? 'center' : 'right'}>
          <FooterLinks links={usefulLinks} />
        </FooterSection>

        {/* Product Help */}
        <FooterSection title="Product Help" textAlign={isMobile ? 'center' : 'left'}>
          <FooterLinks links={productHelpLinks} />
        </FooterSection>

        {/* Download App */}
        <FooterSection title="Download" textAlign={isMobile ? 'center' : 'right'}>
          <DownloadApp>
            <Box component="img" src={googlePlayStoreIcon} sx={{ width: 170 }} />
          </DownloadApp>
        </FooterSection>
      </Grid2>

      {/* Copyright */}
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="body2">
          © {new Date().getFullYear()} {" "}
          <Typography
            variant="body2"
            component="span"
            sx={{ color: theme.palette.primary.main }}
            fontWeight="bold"
          >S3 Library</Typography>.
          All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
