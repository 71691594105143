import React from 'react';
import { Typography } from '@mui/material';

const ContactInfoHeader = () => (
  <>
    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
      Contact Info
    </Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      Get in touch with us for any queries regarding the library management system.
    </Typography>
  </>
);

export default ContactInfoHeader;
