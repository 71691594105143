import HeroSection from "../HeroSection";
import AboutUs from "../AboutUs";
import FeaturesSection from "../Features";
import HowItWorks from "../HowItWorks";
// import PricingSection from "../PricingSection";
import Testimonials from "../Testimonials";
import ContactSection from "../ContactUs";
import Footer from '../Footer';

const Home = () => {
  return (
    <>
      <div id="" style={{ transition: 'all 0.5s ease-in-out', paddingTop: '50px' }}>
        <HeroSection />
      </div>
      <div id="about-us" style={{ transition: 'all 0.5s ease-in-out', paddingTop: '50px', marginTop: "-50px" }}>
        <AboutUs />
      </div>
      <div id="features" style={{ transition: 'all 0.5s ease-in-out', paddingTop: '50px', marginTop: "-50px" }}>
        <FeaturesSection />
      </div>
      <div id="how-it-works" style={{ transition: 'all 0.5s ease-in-out', paddingTop: '50px', marginTop: "-50px" }}>
        <HowItWorks />
      </div>
      {/* <div id="pricing" style={{ transition: 'all 0.5s ease-in-out', paddingTop: '50px', marginTop: "-50px" }}>
        <PricingSection />
      </div> */}
      <div id="testimonials" style={{ transition: 'all 0.5s ease-in-out', paddingTop: '50px', marginTop: "-50px" }}>
        <Testimonials />
      </div>
      <div id="contact-us" style={{ transition: 'all 0.5s ease-in-out', paddingTop: '50px', marginTop: "-50px" }}>
        <ContactSection />
      </div>
      <Footer />
    </>
  );
};

export default Home;
