// StepItem.js
import React from 'react';
import { Box, Typography, Grid2 } from '@mui/material';

const StepItem = ({ stepNumber, title, description, imageSrc }) => {
  // const [contentOrder, imageOrder] = stepNumber % 2 ? [2, 1] : [1, 2]

  const contentOrder = (stepNumber % 2) + 1;
  const imageOrder = 3 - contentOrder; // Ensures contentOrder + imageOrder = 3

  return (
    <Grid2 container spacing={4} >
      <Grid2 order={{ xs: 1, sm: contentOrder }} size={{ xs: 12, sm: 6, md: 8 }}>
        <Box
          sx={{
            fontSize: '96px',
            fontWeight: 'bold',
            color: 'primary.main',
            lineHeight: 1,
          }}
        >
          {stepNumber}
        </Box>

        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', textTransform: 'uppercase', mb: 1, color: 'text.primary' }}
          >
            {title}
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
            {description}
          </Typography>
        </Box>
      </Grid2>
      <Grid2 order={{ xs: 2, sm: imageOrder }} size={{ xs: 12, sm: 6, md: 4 }} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
        {/* Image Section */}
        <Box
          order={1}
          component="img"
          src={imageSrc}
          alt={`Step ${stepNumber}`}
          sx={{
            maxWidth: { xs: '100%', md: '300px' },
            borderRadius: 2,
          }}
        />
      </Grid2>
    </Grid2>
  );
};

export default StepItem;
