import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4, mt: 10 }}>
      <Typography variant="h4" gutterBottom fontWeight="bold">
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Effective Date: 11 Feb 2025
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          1. Introduction
        </Typography>
        <Typography>
          Welcome to <strong>S3 Library</strong> ("we," "our," or "us"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services at <Link href="https://s3library.com" target="_blank">s3library.com</Link> and our mobile application.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography>
          We collect different types of information, including:
        </Typography>
        <ul>
          <li>
            <Typography>
              <b>Personal Information:</b> Name, email address, phone number (if applicable), and login credentials.
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Librarian Payment Details:</b> Bank account details to process payouts.
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Usage Data:</b> IP address, device information, browser type, and activity logs.
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Authentication Data:</b> If you use Google Authentication, we collect the associated user ID and email.
            </Typography>
          </li>
        </ul>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <ul>
          <li>
            <Typography>
              To provide and manage seat booking, check-ins, and study space reservations.
            </Typography>
          </li>
          <li>
            <Typography>
              To process librarian payouts securely.
            </Typography>
          </li>
          <li>
            <Typography>
              To improve system performance and user experience.
            </Typography>
          </li>
          <li>
            <Typography>
              To comply with legal and regulatory requirements.
            </Typography>
          </li>
        </ul>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          4. Data Sharing and Third-Party Services
        </Typography>
        <Typography>
          We do not sell or rent user data. However, we may share information with:
        </Typography>
        <ul>
          <li>
            <Typography>
              <b>Payment Providers (Razorpay, Firebase Authentication)</b> – for processing transactions and authentication.
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Google Cloud Platform (GCP)</b> – for hosting and security infrastructure.
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Legal Authorities</b> – if required by law.
            </Typography>
          </li>
        </ul>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          5. Data Security
        </Typography>
        <Typography>
          We take appropriate security measures to protect your data, including encryption, access controls, and secure payment processing. However, no system is 100% secure, and we encourage users to safeguard their login credentials.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          6. User Rights and Choices
        </Typography>
        <Typography>
          Depending on your location, you may have the right to:
        </Typography>
        <ul>
          <li>
            <Typography>
              <b>Access your data</b> – Request a copy of the information we hold about you.
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Request deletion</b> – Ask us to delete your personal data.
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Withdraw consent</b> – Opt-out of certain data processing activities.
            </Typography>
          </li>
        </ul>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          7. Cookies and Tracking Technologies
        </Typography>
        <Typography>
          Our system may use cookies and similar tracking technologies for analytics and performance improvements. Users can manage cookie preferences through browser settings.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          8. Changes to This Privacy Policy
        </Typography>
        <Typography>
          We may update this Privacy Policy periodically. We encourage you to review it regularly for any changes.
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          9. Contact Information
        </Typography>
        <Typography>
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Typography>
        <Typography>
          📧 Email: <Link href="mailto:support@s3library.com">support@s3library.com</Link>
        </Typography>
        <Typography>
          🌐 Website: <Link href="https://s3library.com" target="_blank">s3library.com</Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
