import React from 'react';
import logoImage from '../../assets/images/S3 Web_logo_blue.svg';
import { Box } from '@mui/material';

const Logo = () => {
  return (
    <Box
      component="img"
      src={logoImage}
      sx={{ maxWidth: 200 }}
    >
    </Box>
  );
};

export default Logo;
