import React from 'react';
import { Box, Typography, Grid2 } from '@mui/material';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';

const ContactSection = () => {
  return (
    <Box sx={{ textAlign: 'center', py: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
        Contact
      </Typography>
      <Typography variant="body1" sx={{ mb: 5 }}>
        Reach out for assistance or more information about our library management system.
      </Typography>
      <Grid2 container justifyContent="space-between" spacing={3} sx={{ alignItems: 'stretch' }}>
        <Grid2 size={{ xs: 12, md: 5 }}>
          <ContactInfo />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 7 }}>
          <ContactForm />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default ContactSection;
