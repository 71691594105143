import axios from "axios";
import { extractDataFromResponse, parseApiErrorResponse } from "./api_utils";
import apisEndPoints from "./apis_routes";
import config from "../config/config";

/**
 * Calls the API and handles the response and errors.
 * @param {Object} options - Options for the API call.
 * @returns {Promise<Object>} - Result of the API call or error.
 */
const callApi = async ({ method, url, data, successCode = 200, successStatus = true, showSuccessToast = false, showErrorToast = false }) => {
	try {
		const response = await axios({ baseURL: config.base_url, timeout: 5000, data, url, method });
		return extractDataFromResponse({ response, showErrorToast, showSuccessToast, successCode, successStatus });
	} catch (error) {
		const errorResponse = parseApiErrorResponse({ error, showToast: showErrorToast });

		return Promise.reject(errorResponse);
	}
};

/**
 * Auth API functions
 */
export const authApi = () => {
	const registerLibrary = async ({ name, email, password, contactNumber, libraryName, locationName }) => {
		const data = {
			name,
			email,
			contactNumber: {
				countryCode: "91",
				phoneNumber: contactNumber
			},
			password,
			libraryName,
			locationName,
		}
		return await callApi({ ...apisEndPoints.auth.registerLibrary, data });
	};

	const checkEmail = async (value) => {
		const data = {
			type: "email",
			email: value,
			contactNumber: {
				countryCode: "",
				phoneNumber: ""
			}
		};

		return await callApi({ ...apisEndPoints.auth.checkCredentials, data });
	};

	const checkNumber = async (value) => {
		const data = {
			type: "number",
			email: "",
			contactNumber: {
				countryCode: "91",
				phoneNumber: value
			}
		};

		return await callApi({ ...apisEndPoints.auth.checkCredentials, data });
	};

	const resetPassword = async (body = { token: "", password: "" }) => {
		return await callApi({ ...apisEndPoints.auth.resetPassword, data: body });
	};

	return {
		registerLibrary,
		checkEmail,
		checkNumber,
		resetPassword,
	};
};

export const contactUsApi = () => {
	const send = async (body) => {
		return await callApi({ ...apisEndPoints.contactUs.send, data: body });
	};

	return {
		send,
	};
};