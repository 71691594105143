import React from "react";
import { Box, Paper, Modal } from "@mui/material";
import TestimonialHeader from "./TestimonialHeader";
import TestimonialText from "./TestimonialText";

const UnifiedTestimonial = ({
  image,
  name,
  designation,
  text,
  rating,
  isModal = false,
  onClose
}) => {

  return (
    <Box
      component={isModal ? Modal : Paper}
      {...(isModal && { open: true, onClose })}
      sx={{
        p: 3,
        borderRadius: 2,
        bgcolor: "action.hover",
        textAlign: "left",
        boxShadow: isModal ? 24 : 3,
        width: 300,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        mx: "auto",
        cursor: isModal ? "default" : "pointer",
      }}
    >
      <TestimonialHeader name={name} image={image} designation={designation} rating={rating} />
      <TestimonialText text={text} isTruncated={!isModal} />
    </Box>
  );
};

export default UnifiedTestimonial;
