// StepsSection.js
import React, { useState } from 'react';
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import StepItem from './StepItem';

import image1 from "../../assets/images/other/image1.png"
import image2 from "../../assets/images/other/image6.png"
import image3 from "../../assets/images/other/image8.png"
import image4 from "../../assets/images/other/image9.png"
import image5 from "../../assets/images/other/image10.png"

// Sample data for the steps
const librarianSteps = [
    {
        stepNumber: 1,
        title: "Sign Up for Free",
        description: "Getting started is quick and free. Simply click the 'Register as Librarian' button and fill in your details, along with basic information about your library, to create your account. Our streamlined registration process ensures you can join the platform and start managing your library in just a few minutes.",
        imageSrc: image1,
    },
    {
        stepNumber: 2,
        title: "Add Library Details",
        description: "Showcase your library to students by providing essential details such as the library’s name, address, contact information, and operating hours. This ensures students can easily discover and select your library for their study needs. Use our intuitive layout tools to define your library’s floor plan. Create floors, map out seating arrangements, and assign precise coordinates to each seat, offering students a clear and accurate view of available study spaces.",
        imageSrc: image2,
    },
    // {
    //     stepNumber: 3,
    //     title: "Set Up Your Library Layout",
    //     description: "Define your library’s floor plan with our user-friendly layout tools. Create floors, map out seating arrangements, and assign specific coordinates to each seat, giving students a clear and accurate view of your available study spaces.",
    //     imageSrc: image3,
    // },
    {
        stepNumber: 3,
        title: "Manage Seats in Real-Time",
        description: "Keep track of seat bookings and student check-ins with real-time updates. Easily monitor which seats are occupied, which are free, and optimize space usage efficiently to avoid overcrowding and enhance user satisfaction.",
        imageSrc: image4,
    },
    {
        stepNumber: 4,
        title: "Engage and Communicate with Students",
        description: "Use our platform to send important updates, answer queries, and interact with students directly. Build a stronger connection with your library’s community by providing a seamless and personalized experience.",
        imageSrc: image5,
    },
];

const studentSteps = [
    {
        stepNumber: 1,
        title: "Download the App",
        description: "Start your journey by downloading the mobile app from the official store. The app is your gateway to discovering libraries, exploring seating options, and managing your study schedules effortlessly.",
        imageSrc: image1,
    },
    {
        stepNumber: 2,
        title: "Create Your Student Account",
        description: "Sign up in just a few steps by entering your name, email, and a secure password. Your student account unlocks access to a wide network of libraries, allowing you to book and manage your study sessions with ease.",
        imageSrc: image2,
    },
    {
        stepNumber: 3,
        title: "Find the Perfect Study Spot",
        description: "Search for libraries in your area or around the globe. Use filters to view seat availability, library amenities, and other details to choose the study spot that suits your preferences and schedule.",
        imageSrc: image3,
    },
    {
        stepNumber: 4,
        title: "Book a Seat with Ease",
        description: "Reserve your preferred seat by selecting the library, date, and time slot. The booking system ensures you always have a reserved spot, letting you focus on your studies without worrying about availability.",
        imageSrc: image4,
    },
    {
        stepNumber: 5,
        title: "Check In and Start Studying",
        description: "Upon arrival, scan the library’s QR code at the entrance to check in seamlessly. Access your reserved seat and enjoy a distraction-free environment designed to help you achieve your academic goals.",
        imageSrc: image5,
    },
];

const StepsSection = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event, newValue) => setActiveTab(newValue);

    return (
        <Box sx={{
            px: { xs: 2, md: 4 },
            py: 4,
            textAlign: 'center',
            bgcolor: 'action.hover',
            borderRadius: 4,
        }}>
            <Container maxWidth="lg">
                {/* Section Title */}
                <Typography variant="h4" sx={{ mb: 2 }}>
                    How It Works
                </Typography>

                {/* Tab Menu */}
                <Tabs value={activeTab} onChange={handleChange} centered sx={{ mb: 2 }}>
                    <Tab label="For Librarians" sx={{ textTransform: "none" }} />
                    <Tab label="For Students" sx={{ textTransform: "none" }} />
                </Tabs>


                {/* Steps */}
                {(activeTab === 0 ? librarianSteps : studentSteps).map((step, index) => (
                    <StepItem key={index} {...step} />
                ))}
            </Container>
        </Box>
    );
};

export default StepsSection;
