import React from 'react';
import { Box, Typography } from '@mui/material';
import Values from './Values';

const missionAndVision = [
  {
    title: "Our Mission",
    description: "Our mission is to empower students with convenient and affordable access to study spaces, while enabling libraries to manage their resources digitally and transparently. We are dedicated to creating an environment where focused learning is accessible and enhanced by modern technology, removing the barriers of outdated library practices."
  },
  {
    title: "Our Vision",
    description: "Our vision is to redefine libraries as accessible, adaptable hubs that cater to the diverse study needs of every student. Through our innovative library management platform, we aim to simplify resource management and enhance the library experience, making it as seamless and convenient as studying from home. By integrating modern technology, we ensure students and librarians have the tools they need for success in a connected, digital era."
  },
]

const MissionAndVision = ({ title, description }) => (
  <Box sx={{ mb: 4 }}>
    <Typography variant="h6" sx={{ mb: 1 }}>
      {title}
    </Typography>
    <Typography variant="body1" color="text.secondary">
      {description}
    </Typography>
  </Box>
);

const AboutSection = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, md: 4 },
        py: 4,
        textAlign: 'center',
        bgcolor: 'action.hover',
        borderRadius: 4,
      }}
    >
      {/* Title and Introduction */}
      <Typography variant="h4" sx={{ mb: 2 }}>
        About Us
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4, mx: { xs: 1, md: 6 } }}>
        At S3 Library, we are a driven team on a mission to transform library experiences for both students and librarians. Our journey began when one of our founders faced common but critical issues—overcrowded study spaces, outdated fee systems, and inefficient attendance tracking. These challenges sparked our passion to create a digital platform that reimagines library management. By streamlining seat reservations, attendance, and fee handling, we’re empowering libraries to better serve their communities and helping students access the resources they need, when they need them
      </Typography>

      {/* Mission, Vision, Values, and CTA */}
      {missionAndVision.map((obj) => (<MissionAndVision {...obj} />))}
      <Values />
      {/* <CTA /> */}
    </Box>
  );
};

export default AboutSection;
