import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Facebook, Twitter, LinkedIn } from '@mui/icons-material';
import config from '../../config/config';

const linkedInUrl = config.linkedInUrl;
const faceBookUrl = config.faceBookUrl;
const twitterUrl = config.twitterUrl;

const SocialIcons = ({ isMobile }) => {
  return (
    <Box display="flex" justifyContent={isMobile ? 'center' : 'flex-start'}>
      <IconButton href={faceBookUrl} target="_blank" aria-label="Facebook" color="primary">
        <Facebook />
      </IconButton>
      <IconButton href={twitterUrl} target="_blank" aria-label="Twitter" color="primary">
        <Twitter />
      </IconButton>
      <IconButton href={linkedInUrl} target="_blank" aria-label="LinkedIn" color="primary">
        <LinkedIn />
      </IconButton>
    </Box>
  );
};

export default SocialIcons;
