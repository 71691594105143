import React from "react";
import { Typography } from "@mui/material";

const TestimonialText = ({ text, isTruncated }) => {
  const charLimit = 90; // Limit words for preview
  const truncatedText =
    text.replace(" ", "").length > charLimit
      ? `${text.slice(0, charLimit)} ......`
      : text;

  return (
    <Typography
      variant="body1"
      sx={{
        color: "text.secondary",
        minHeight: "70px",
        marginTop: 2,
      }}
    >
      {isTruncated ? truncatedText : text}
    </Typography>
  );
};

export default TestimonialText;
