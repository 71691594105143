import React from 'react';
import { Box } from '@mui/material';
import heroImg from '../../assets/images/hero-img.png'; // Update with your image path

const HeroImage = () => {
  return (
    <Box
      component="img"
      src={heroImg}
      alt="heroImg"
      sx={{ maxWidth: '100%', height: 'auto' }}
    />
  );
};

export default HeroImage;
