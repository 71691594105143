import React from "react";
import { Box, Typography } from "@mui/material";

const UnderDevelopment = () => {
  return (
    <Box
      sx={{
        height: "50vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography variant="h4" sx={{ mt: 2, fontWeight: "bold" }}>
        Under Development
      </Typography>
      <Typography variant="body1" sx={{ mt: 1, color: "gray" }}>
        We're working hard to bring you this feature soon. Stay tuned!
      </Typography>
    </Box>
  );
};

export default UnderDevelopment;
