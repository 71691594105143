import React, { useState } from 'react';
import { Box, Typography, Tab, Tabs, Grid2 } from '@mui/material';
import librarianFeatureImage from "../../assets/images/librarian-features.svg";
import studentFeatureImage from "../../assets/images/students-features.png";
import FeatureItem from "./FeatureItem";
import { AdminPanelSettings, AirlineSeatReclineNormal, Explore, Grade, Group, Insights, Layers, Notifications, Payment, Schedule } from '@mui/icons-material';

// Updated Features Based on Mission and Vision
const studentFeatures = [
    {
        Icon: Explore,
        title: "Accessible Libraries Worldwide",
        description: "Connect with libraries globally to find the ideal study spot.",
    },
    {
        Icon: AirlineSeatReclineNormal,
        title: "Live Seat Availability",
        description: "Check real-time seat availability for quick and easy booking.",
    },
    {
        Icon: Payment,
        title: "Flexible Booking Options",
        description: "Book seats for specific days with fair, flexible pricing.",
    },
    {
        Icon: Notifications,
        title: "Stay Informed",
        description: "Receive updates on seat availability and reservation status.",
    },
    {
        Icon: Grade,
        title: "Earn Study Rewards",
        description: "Get rewarded for regular library visits and study dedication.",
    },
];

const librarianFeatures = [
    {
        Icon: Group,
        title: "Insightful Student Analytics",
        description: "Understand students’ needs with data-driven insights on usage.",
    },
    {
        Icon: Schedule,
        title: "Customizable Hours",
        description: "Adjust library timings to cater to varying student schedules.",
    },
    {
        Icon: Layers,
        title: "Dynamic Floor Management",
        description: "Organize seating and update floor plans seamlessly.",
    },
    {
        Icon: Insights,
        title: "Performance Tracking",
        description: "Analyze library performance with comprehensive metrics.",
    },
    {
        Icon: AdminPanelSettings,
        title: "Secure Access Control",
        description: "Enhance security with QR code access and track library entries.",
    },
];

const Features = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (event, newValue) => setActiveTab(newValue);

    return (
        <Box sx={{ paddingY: 4, textAlign: 'center' }}>
            {/* Section Title */}
            <Typography variant="h4" sx={{ mb: 2 }}>
                Our Features
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                Supporting accessible, flexible, and innovative study solutions for students and libraries.
            </Typography>

            {/* Tab Menu */}
            <Tabs value={activeTab} onChange={handleChange} centered sx={{ mb: 4 }}>
                <Tab label="For Librarians" sx={{ textTransform: "none" }} />
                <Tab label="For Students" sx={{ textTransform: "none" }} />
            </Tabs>

            {/* Feature Details */}
            <Grid2 container spacing={4} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* Feature List on the Left */}
                <Grid2 size={{ xs: 12, sm: 6 }}>
                    {(activeTab === 0 ? librarianFeatures : studentFeatures).map((obj, index) => (
                        <FeatureItem key={index} {...obj} />
                    ))}
                </Grid2>

                {/* Illustration/Image on the Right */}
                <Grid2 size={{ xs: 12, sm: 6 }} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                        component="img"
                        src={activeTab === 0 ? librarianFeatureImage : studentFeatureImage}
                        alt={activeTab === 0 ? "Librarian Features Illustration" : "Student Features Illustration"}
                        style={{ maxWidth: '100%', height: 'auto', maxHeight: '350px' }}
                    />
                </Grid2>
            </Grid2>
            <Typography variant="subtitle2" sx={{ mt: 2, color: 'text.secondary' }}>
                {activeTab === 0
                    ? "Empowering librarians to enhance study environments through digital management."
                    : "Making study spaces accessible, affordable, and convenient for students."}
            </Typography>
        </Box>
    );
};

export default Features;
