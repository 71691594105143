import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'Quicksand, League Spartan, Jaldi, Numans, Dongle, Inter, Onest, Lexend', // Include all fonts here for global usage
        h1: {
            fontFamily: 'League Spartan, sans-serif',
            fontWeight: 700,
            fontSize: '3.5rem', // Increased size for better visibility
        },
        h2: {
            fontFamily: 'League Spartan, sans-serif',
            fontWeight: 600,
            fontSize: '2.25rem', // Slightly smaller to make it distinct from h1
        },
        h3: {
            fontFamily: 'League Spartan, sans-serif',
            fontWeight: 600, // Increased weight for better emphasis
            fontSize: '1.75rem', // Adjusted size for better hierarchy
        },
        h4: {
            fontFamily: 'League Spartan, sans-serif',
            fontWeight: 600, // Increased weight for clarity
            fontSize: '1.5rem', // Adjusted size to be consistent with h3
        },
        h5: {
            fontFamily: 'League Spartan, sans-serif',
            fontWeight: 500,
            fontSize: '1.25rem', // Slightly smaller for less emphasis
        },
        h6: {
            fontFamily: 'League Spartan, sans-serif',
            fontWeight: 500,
            fontSize: '1.125rem', // Further reduced size for small headings
        },
        p: {
            fontFamily: 'Lexend, sans-serif',
            fontWeight: 400, // Regular weight for paragraph text
            fontSize: '1rem', // Default paragraph size
        },
        body1: {
            // Changed from 'Jaldi, sans-serif' to 'Source Sans Pro, Arial, sans-serif' 
            // due to rendering issues in some components like TextField. 
            // 'Source Sans Pro' is a widely supported and readable font, 
            // making it a reliable choice for body text across the system.
            //  fontFamily: 'Jaldi, sans-serif',
            fontFamily: 'Source Sans Pro, Arial, sans-serif',
            fontSize: '1rem',
            fontWeight: 400, // Regular weight for body text
        },
        body2: {
            fontFamily: 'Numans, sans-serif',
            fontSize: '0.95rem', // Slightly smaller for secondary content
            fontWeight: 400, // Regular weight
        },
        subtitle1: {
            fontFamily: 'Dongle, sans-serif',
            fontSize: '1.25rem', // Adjusted size for better readability
            fontWeight: 400,
        },
        subtitle2: {
            fontFamily: 'Quicksand, sans-serif',
            fontSize: '1.125rem', // Slightly smaller for secondary text
            fontWeight: 400,
        },
        button: {
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600, // Bold weight for buttons
            fontSize: '1rem', // Standard button size
        },
        caption: {
            fontFamily: 'Lexend, sans-serif',
            fontSize: '0.875rem', // Slightly larger for captions
            fontWeight: 400,
        },
        overline: {
            fontFamily: 'Dongle, sans-serif',
            fontSize: '0.75rem',
            fontWeight: 400,
        },
        navLink: {
            fontFamily: 'League Spartan, sans-serif', // Keeping it consistent with your design
            fontSize: '1rem',
            fontWeight: 500,
            letterSpacing: '0.05rem',
        },
    },
    palette: {
        primary: {
            main: '#08518b',
            light: '#E0F7FA', // Adjust based on preferred light tone
        },
        secondary: {
            main: '#00b3de',
            light: '#FFFDE7', // Adjust based on preferred light tone
        },
        success: {
            main: '#388E3C',
            light: '#F1F8E9',
        },
        info: {
            main: '#3949AB',
            light: '#c0c6ed',
        },
        action: {
            hover: '#E8EAF6',
        },
    },
});

export default theme;
