import React from 'react';
import { Button } from '@mui/material';
import config from '../../config/config';

const DownloadApp = ({ children, variant = "text" }) => {
  const appDownloadUrl = config.studentAppDownloadUrl;

  return (
    <Button variant={variant} target='_blank' href={appDownloadUrl} sx={{ textTransform: 'none' }} >
      {children}
    </Button>
  );
};

export default DownloadApp;
