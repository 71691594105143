import React from 'react';
import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import theme from './theme';

import Header from './components/Header';
import Home from './components/Home';
import NotFound from "./components/NotFound";
import PrivacyAndPolicy from "./components/PrivacyAndPolicy";
import UnderDevelopment from "./components/UnderDevelopment";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Container maxWidth="lg" sx={{ padding: "0px 2% !important" }}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contect-us" element={<Home />} />
            {/* <Route path="/register-library" element={<LibraryRegistration />} /> */}
            <Route path="/download-student-app" element={<UnderDevelopment />} />
            <Route path="/services" element={<UnderDevelopment />} />
            <Route path="/faq" element={<UnderDevelopment />} />
            <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
            <Route path="/support" element={<UnderDevelopment />} />
            <Route path="/terms-conditions" element={<UnderDevelopment />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Container>
      </Router>
    </ThemeProvider>
  );
};

export default App;
